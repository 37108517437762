/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import StateButton from "@/app/components/shared/state-button/StateButton.vue";
import { AgGridVue } from '@ag-grid-community/vue';
import { AllModules, ModuleRegistry } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import ConversationModal from "@/app/components/shared/conversation-modal/ConversationModal.vue";
import * as bootstrap from "bootstrap"
import $ from 'jquery';
//import { BModal } from 'bootstrap-vue'
//Vue.component('b-modal', BModal)
import Axios from "axios";
import { BASE_URL_ORCHESTATOR } from '@/config';
@Component({
    name: "conversations-table",
    components: { StateButton, AgGridVue, ConversationModal }
})
export default class ConversationsTable extends VueWizard {
    public dataFrame: any = [];
    public conversations: any = [];

    @Prop() readonly projects !: any;
    @Prop() readonly agents !: any;
    @Prop() readonly notRegisteredValue !: string;
    @Prop() readonly parentBus !: any;
    public EventBus: Vue = new Vue();
    public fields: any[] = [];
    public statuses: any[] = [
        { id: '', text: 'TODOS' },
        { id: 'NEW', text: 'NUEVA' },
        { id: 'IN_QUEUE', text: 'EN COLA' },
        { id: 'ACTIVE', text: 'ACTIVAS' },
        { id: 'RESOLVED', text: 'RESUELTAS' },
        { id: 'TRANSFERRED', text: 'TRANSFERIDAS' },
    ];
    public modules: any = null;
    public localeText: any = null;
    public isBusy = false;
    public notExistingValue = '';
    public currentPage: any = 1;
    public perPage: any = 10;
    public totalRows: any = 10;
    public pageOptions: any = [10, 20, 30, 50, { value: 100, text: "100" }];
    public columns = {
        channelId: 'ID del canal',
        channelName: 'Nombre en el canal',
        firstName: 'Nombres',
        lastName: 'Apellidos',
        idNumber: 'Documento',
        phoneNumber: 'Número telefónico',
        address: 'Dirección',
        city: 'Ciudad',
        email: 'Correo',
        postalCode: 'Código Postal',
        project: 'Proyecto',
        status: 'Estado',
        bussinesStatus: 'Estado de negocio',
        currentAgent: 'Agente a cargo',
        channel: 'Canal',
        topics: 'Tópicos suscrito'
    };
    public gridOptions: any = {

        // EVENTS
        // Add event handlers
        onRowDoubleClicked: (event: any) => this.showConversationModal(event),
        //onColumnResized: event => console.log('A column was resized')
        onGridReady: (event: any) => this.onGridReady(event)

        // CALLBACKS
        //isScrollLag: () => false
    };
    public selectedProject: any;
    public selectedAgent: any;
    public selecdtedStatus: any;

    created() {
        console.log(this.agents);
        this.selectedProject = this.projects[0];
        this.selectedAgent = {};
        this.selecdtedStatus = this.statuses[0];
        ModuleRegistry.registerModules(AllModules);
        this.retrieveInfo();
    }
    async retrieveInfo() {


        this.fillDataFrame();

    }

    mounted() {
        this.modules = AllModules;
        this.fields = [
            { label: 'ID del canal', key: 'channelId', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { label: 'Nombre en el canal', key: 'channelName', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { label: 'Estado', field: 'status', key: 'agSetColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            /* { headerName: 'Nombres', field: 'firstName', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
             { headerName: 'Apellidos', field: 'lastName', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },*/
            /*{ headerName: 'Documento', field: 'idNumber', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { headerName: 'Número telefónico', field: 'phoneNumber', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { headerName: 'Dirección', field: 'address', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100 },
            { headerName: 'Ciudad', field: 'city', filter: 'agSetColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { headerName: 'Correo', field: 'email', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { headerName: 'Código Postal', field: 'postalCode', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },*/
            { label: 'Proyecto', key: 'project', filter: 'agSetColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { label: 'Estado de negocio', key: 'bussinesStatus', filter: 'agSetColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { label: 'Agente', key: 'currentAgent', filter: 'agSetColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { label: 'Canal', key: 'channel', filter: 'agSetColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
            { label: 'Tópicos Suscritos', key: 'topics', filter: 'agSetColumnFilter', minWidth: 100, maxWidth: 200, minHeight: 100, sortable: true },
        ];


    }
    onGridReady(params: any) {
        console.log(params);
    }

    showConversationModal(event: any) {

        let ComponentClass = Vue.extend(ConversationModal)
        let instance = new ComponentClass({
            propsData: {
                elementId: event.rowIndex + 'conversationModal',
                currentConversation: this.conversations[event.rowIndex],
                agentConversations: [],
                currentCloseConversations: [],
                eventBus: this.EventBus,
            }

        })


        instance.$mount() // pass nothing
        $("#container").append(instance.$el);

        let modal: any = $("#" + event.rowIndex + 'conversationModal');
        console.log(modal);
        modal.modal('show');
        //this.$root.$emit('bv::show::modal', event.rowIndex + 'conversationModal')

    }

    async getConversations(params: any) {

        let data = await Axios.get(`${BASE_URL_ORCHESTATOR}/conversation/${this.selectedProject._id}`, {
            params: params
        });

        return data;
    }
    async getItems(ctx: any) {
        try {
            this.isBusy = true;
            let data = await this.getConversations({
                limit: this.perPage,
                pagenum: this.currentPage - 1,
                agent:this.selectedAgent!.agent!=undefined? this.selectedAgent!.agent!.agent:null,
                status: this.selecdtedStatus.id,
            });
            let dataCount: any = await this.getConversations({

                agent:this.selectedAgent!.agent!=undefined? this.selectedAgent!.agent!.agent:null,
                status: this.selecdtedStatus.id,
            });

            // Here we could override the busy state, setting isBusy to false
            this.conversations = data.data;
            if (dataCount.data.length) {
                this.totalRows = dataCount.data.length;
                //this.totalRows = this.totalRows + this.perPage;
            }
            console.log(this.totalRows);

            let dataframes = await this.fillDataFrame();
            console.log(dataframes);
            this.isBusy = false;
            return dataframes || [];
        } catch (error) {
            console.log(error);

            this.isBusy = false
            return [];
        }
    }




    async asyncForEach(array: any, callback: any) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
    async fillDataFrame() {

        this.dataFrame = [];

        try {
            for (let i = 0; i < this.conversations.length; i++) {
                let conv = this.conversations[i];

                let row: any = {
                    channelId: '',
                    channelName: '',
                    /*  firstName: '',
                      lastName: '',*/
                    /*idNumber: '',
                    phoneNumber: '',
                    address: '',
                    city: '',
                    email: '',
                    postalCode: '',*/
                    project: '',
                    status: '',
                    bussinesStatus: '',
                    currentAgent: '',
                    channel: '',
                    topics: ''
                }

                row.channelId = this.verifyValue(conv.currentConsumer).split('@')[0];
                row.channelName = conv!.variables != undefined && conv!.variables!.CONSUMER_NAME != undefined ? this.verifyValue(conv!.variables!.CONSUMER_NAME.value) : this.notRegisteredValue;

                if (conv.contactData) {
                    /* row.firstName = this.verifyValue(conv.contactData.name);
                     row.lastName = this.verifyValue(conv.contactData.lastName);*/
                    /* row.idNumber = this.verifyValue(conv.contactData.identification);
                     row.phoneNumber = this.verifyValue(conv.contactData.phoneNumber);
                     row.address = this.verifyValue(conv.contactData.address);
                     row.city = this.verifyValue(conv.contactData.city);
                     row.email = this.verifyValue(conv.contactData.email);
                     row.postalCode = this.verifyValue(conv.contactData.postalCode);*/
                } else {
                    /*  row.firstName = this.notRegisteredValue;
                      row.lastName = this.notExistingValue*/
                    /* row.idNumber = this.notExistingValue
                     row.phoneNumber = this.notExistingValue
                     row.address = this.notExistingValue
                     row.city = this.notExistingValue
                     row.email = this.notExistingValue
                     row.postalCode = this.notExistingValue*/
                }



                if (conv.currentAgent != null || conv.currentAgent != undefined) {
                    row.currentAgent = this.getAgentUsername(conv.currentAgent._id);
                } else {
                    row.currentAgent = this.notRegisteredValue;
                }

                if (conv.bussinesStatus != null || conv.bussinesStatus != undefined) {
                    row.bussinesStatus = conv.bussinesStatus;
                } else {
                    row.bussinesStatus = this.notRegisteredValue;
                }

                if (conv.status != null || conv.status != undefined) {
                    row.status = conv.status
                } else {
                    row.status = this.notRegisteredValue;
                }

                if (conv.project != null || conv.project != undefined) {
                    row.project = this.getProjectName(conv.project);
                } else {
                    row.project = this.notRegisteredValue;
                }

                if (conv.currentChannel != null || conv.currentChannel != undefined) {
                    row.channel = conv.currentChannel;
                } else {
                    row.channel = this.notRegisteredValue;
                }

                if (conv.topics != undefined && conv.topics.length > 0) {
                    row.topics = conv.topics[0].split("_").splice(1, conv.topics[0].length - 2).join(" ");
                } else {
                    row.topics = this.notExistingValue;
                }

                this.dataFrame.push(row);
            }
        } catch (err) {
            throw err;
        }

        return this.dataFrame;

    }
    getProjectName(id: string) {
        let name = 'Sin nombre'
        this.projects.forEach((project: any) => {
            if (project._id === id) {
                name = project.name;
            }
        })
        return name;
    }

    getAgentUsername(id: string) {
        let username = 'No existe';
        this.agents.forEach((agent: any) => {
            if (agent.agent.agent === id) {
                username = agent.username;
            }
        })
        return username;
    }

    verifyValue(value: any) {
        return value != undefined ? value : this.notExistingValue;
    }

    filter() {
        console.log(this.selectedProject);
        this.$root.$emit('bv::refresh::table', 'coversations')

    }


}